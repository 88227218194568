var render = function render(){
  var _vm$product$barcode;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "global-product-modal",
      "hide-header": "",
      "hide-footer": "",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "global-product"
  }, [_c('div', {
    staticClass: "barcode-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/Barcode.svg",
      "alt": "Barcode"
    }
  }), _vm.product.name ? _c('p', {
    staticClass: "info-barcode"
  }, [_vm._v(" Штрих-код не найден в вашей базе, но мы нашли товар с таким же штрих-кодом в глобальном справочнике, хотите добавить? ")]) : _c('p', {
    staticClass: "info-barcode"
  }, [_vm._v(" Штрихкод в базе не найден. ")]), _vm.product.name ? _c('div', {
    staticClass: "product-block"
  }, [_c('b', {
    staticClass: "product-name"
  }, [_vm._v(_vm._s(_vm.product.name))]), _c('div', {
    staticClass: "art-barcode"
  }, [_c('p', [_vm._v("арт: " + _vm._s(_vm.product.articul || '-'))]), _c('p', [_vm._v("шк: " + _vm._s((_vm$product$barcode = _vm.product.barcode) === null || _vm$product$barcode === void 0 ? void 0 : _vm$product$barcode[0]))])])]) : _vm._e()])]), _c('div', {
    staticClass: "buttons"
  }, [_vm.product.name ? _c('e-button', {
    attrs: {
      "size": "l",
      "variant": "primary"
    },
    on: {
      "click": _vm.createGlobalProduct
    }
  }, [_vm._v(" Добавить из глобального справочника ")]) : _vm._e(), _c('e-button', {
    attrs: {
      "size": "l",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.newProduct
    }
  }, [_vm._v(" Создать новый ")]), _c('e-button', {
    staticClass: "cancel",
    attrs: {
      "size": "l",
      "variant": "link"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }