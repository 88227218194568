var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-scan",
      "hide-header": "",
      "hide-footer": "",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "b-scan d-flex"
  }, [_c('p', {
    staticClass: "center"
  }, [_vm._v("Штрихкод в базе не найден.")]), _c('div', {
    staticClass: "barcode__item"
  }, [_c('barcode', {
    attrs: {
      "value": _vm.barcode,
      "display-value": true
    }
  })], 1)]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.createPrduct
    }
  }, [_vm._v(" Создать товар ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }