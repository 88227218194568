<template>
  <b-modal
    id="global-product-modal"
    hide-header
    hide-footer
    centered
  >
    <div class="global-product">
      <div class="barcode-block">
        <img
          src="/img/icons/Barcode.svg"
          alt="Barcode"
        />
        <p
          v-if="product.name"
          class="info-barcode"
        >
          Штрих-код не найден в вашей базе, но мы нашли товар с таким же штрих-кодом в глобальном справочнике, хотите
          добавить?
        </p>
        <p
          v-else
          class="info-barcode"
        >
          Штрихкод в базе не найден.
        </p>
        <div
          v-if="product.name"
          class="product-block"
        >
          <b class="product-name">{{ product.name }}</b>
          <div class="art-barcode">
            <p>арт: {{ product.articul || '-' }}</p>
            <p>шк: {{ product.barcode?.[0] }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <e-button
        v-if="product.name"
        size="l"
        variant="primary"
        @click="createGlobalProduct"
      >
        Добавить из глобального справочника
      </e-button>
      <e-button
        size="l"
        variant="outline-primary"
        @click="newProduct"
      >
        Создать новый
      </e-button>
      <e-button
        class="cancel"
        size="l"
        variant="link"
        @click="hideModal"
      >
        Отмена
      </e-button>
    </div>
  </b-modal>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        default: null
      },
      barcode: {
        type: String,
        default: ''
      },
      type_add: {
        type: String,
        default: ''
      }
    },
    methods: {
      hideModal() {
        this.$emit('clearInput')
        this.$bvModal.hide('global-product-modal')
      },

      showModal() {
        this.$bvModal.show('global-product-modal')
      },

      newProduct() {
        if (this.type_add === 'modal') {
          this.$emit('clearInput')
          this.hideModal()
          this.$emit('openCreateModal', [{ id: 1, value: 1, barcode: { barcode: this.barcode } }], true)
        } else {
          this.$emit('clearInput')
          this.hideModal()
          this.$router.push({
            name: 'products.new',
            query: { barcode: this.barcode }
          })
        }
      },

      createGlobalProduct() {
        if (this.type_add === 'modal') {
          this.$emit('clearInput')
          this.hideModal()
          this.$emit('openCreateModal', [
            { id: 1, value: 1, barcode: { barcode: this.barcode }, product: this.product }
          ])
        } else {
          this.$emit('clearInput')
          this.hideModal()
          this.$router.push({
            name: 'products.new',
            query: { barcode: this.barcode, product: this.product ?? {} }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cancel {
    text-decoration: none;
    color: #888888;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  p {
    margin: 0;
  }
  #global-product-modal {
    width: 382ex;
  }
  .global-product {
    .barcode-block {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px dashed #bcbcbc;
      border-radius: 8px;
      margin-bottom: 22px;
      img {
        margin-top: 4px;
        margin-bottom: 12px;
      }
      .info-barcode {
        width: 270px;
        justify-content: center;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #313131;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    button {
      &:first-child {
        margin-bottom: 8px;
      }
      &:nth-child(2) {
        margin-bottom: 8px;
      }
      &:nth-child(3) {
        margin-bottom: 20px;
      }
    }
  }
  .product-block {
    margin-top: 16px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    .art-barcode {
      display: flex;
      b {
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
</style>
