<template>
  <b-modal
    id="modal-scan"
    hide-header
    hide-footer
    centered
  >
    <div class="b-scan d-flex">
      <p class="center">Штрихкод в базе не найден.</p>
      <div class="barcode__item">
        <barcode
          :value="barcode"
          :display-value="true"
        />
      </div>
    </div>
    <b-button
      variant="primary"
      @click="createPrduct"
    >
      Создать товар
    </b-button>
    <b-button
      variant="outline-primary"
      @click="hideModal"
    >
      Отмена
    </b-button>
  </b-modal>
</template>

<script>
  import VueBarcode from 'vue-barcode'

  export default {
    components: {
      barcode: VueBarcode
    },

    props: {
      barcode: {
        type: String,
        default: ''
      },
      type_add: {
        type: String
      }
    },

    methods: {
      hideModal() {
        this.$emit('clearInput')
        this.$bvModal.hide('modal-scan')
      },

      showModal() {
        this.$bvModal.show('modal-scan')
      },

      createPrduct() {
        if (this.type_add === 'modal') {
          this.$emit('clearInput')
          this.$bvModal.hide('modal-scan')
          this.$emit('openCreateModal', [{ id: 1, value: 1, barcode: { barcode: this.barcode } }])
        } else {
          this.$emit('clearInput')
          this.$bvModal.hide('modal-scan')
          this.$router.push({
            name: 'products.new',
            query: { barcode: this.barcode }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #modal-scan {
    .modal-content {
      padding: 22px 0 !important;
    }

    .b-scan {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px dashed #bcbcbc;
      border-radius: 8px;
      width: 100%;
      /*height: 164px;*/
      margin-bottom: 24px;

      p {
        font-size: 12px;
        line-height: 16px;
        color: #313131;
        margin-bottom: 0;
        margin-top: 10px;
      }

      .barcode__item {
        overflow: hidden;
      }
    }

    button {
      width: 100%;
      height: 42px;
      justify-content: center;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 22px;
      }
    }
  }
</style>
